<template>
    <div>
 <div class="tableform">
            <el-form ref="form" :model="project" label-width="180px">
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <div class="gezi">
                      <el-form-item label="项目名称：">
                        {{ project.name }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="建筑面积（m²）/层数：">
                        {{ project.building_area }}/{{project.floor}}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="建设单位：">
                        {{ project.build_company }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="施工单位：">
                        {{ project.work_build }}
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple">
                    <div class="gezi">
                      <el-form-item label="项目地址：">
                        {{ project.address }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="气候区域：">
                        <span v-if="project.weather == 1">夏热冬冷</span>
                        <span v-if="project.weather == 2">寒冷</span>
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="设计单位：">
                        {{ project.design_company }}
                      </el-form-item>
                    </div>
                    <div class="gezi">
                      <el-form-item label="监理单位：">
                        {{ project.manger_company }}
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-form ref="formtable" :model="formtable" label-width="180px">
            <div id="engerytable" class="engerytable">
              <table border="1" cellspacing="0" width="100%">
                <tbody>
                  <tr>
                    <th colspan="8" scope="col" style="height: 40px">
                      &nbsp;测评内容
                    </th>
                    <th scope="col">&nbsp;测评方法</th>
                    <th scope="col">&nbsp;测评结果</th>
                  </tr>
                  <tr>
                    <th rowspan="3" scope="row">基础类&nbsp;</th>
                    <td colspan="3" style="background-color:#a3c1dd2d">&nbsp;供暖耗能（kW·h/m²）</td>
                    <td colspan="2">
                     <span v-if="formtable.heatEnergy">{{formtable.heatEnergy}}</span>
                     <span v-else>——</span>
                    </td>
                    <td colspan="2" rowspan="3" style="background-color:#a3c1dd2d">&nbsp; 相对节能率（%）</td>
                    <td rowspan="3">
                                <span v-if="formtable.evaluationWay_heatEnergy == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatEnergy == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatEnergy == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatEnergy == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatEnergy == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatEnergy == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatEnergy == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td rowspan="3">
                      <span v-if="formtable.evaInstitu_heatEnergy">{{formtable.evaInstitu_heatEnergy}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;空调能耗（kW·h/m²）</td>
                    <td colspan="2">
                      <span v-if="formtable.airEnergy">{{formtable.airEnergy}}</span>
                      <span v-else>——</span>
                    </td>
                    <!-- <td><input v-model="formtable.evaluationWay_airEnergy" type="text"></td>
                  <td><input v-model="formtable.evaInstitu_airEnergy" type="text"></td> -->
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">
                      单位建筑面积全年能耗量（kWh/m² )
                    </td>
                    <td colspan="2">
                      <span v-if="formtable.buildEnergy"> {{formtable.buildEnergy}}</span>
                      <span v-else>——</span>
                    
                    </td>
                    <!-- <td><input v-model="formtable.evaluationWay_buildEnergy" type="text"></td>
                  <td><input v-model="formtable.evaInstitu_buildEnergy" type="text"></td> -->
                  </tr>
                  <tr>
                    <th
                      :rowspan="
                        29 +
                        jizu.length +
                        jizu2.length +
                        jizu3.length +
                        jizu4.length
                      "
                      scope="row"
                    >
                      &nbsp;规定项
                    </th>
                    <td rowspan="5" style="background-color:#a3c1dd2d">&nbsp;围护结构</td>
                    <td colspan="2" style="background-color:#a3c1dd2d">&nbsp;外窗、透明幕墙气密性</td>
                    <td colspan="4">
                     <span v-if="formtable.wallGas"> {{formtable.wallGas}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_wallGas == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallGas == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallGas == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallGas == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallGas == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallGas == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallGas == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_wallGas">{{formtable.evaInstitu_wallGas}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="background-color:#a3c1dd2d">热桥部位&nbsp;</td>
                    <td colspan="4">
                      <span v-if="formtable.hotPart">{{formtable.hotPart}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                         <span v-if="formtable.evaluationWay_hotPart == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotPart == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotPart == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotPart == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotPart == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotPart == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotPart == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_hotPart">{{formtable.evaInstitu_hotPart}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">门窗保温&nbsp;</td>
                    <td colspan="4">
                     <span v-if="formtable.doorHeat">{{formtable.doorHeat}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                   <span v-if="formtable.evaluationWay_doorHeat == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_doorHeat == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_doorHeat == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_doorHeat == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_doorHeat == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_doorHeat == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_doorHeat == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_doorHeat">{{formtable.evaInstitu_doorHeat}}</span>
                       <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">透明幕墙可开启面积</td>
                    <td colspan="4">
                      <span v-if="formtable.wallOpenArea">{{formtable.wallOpenArea}}</span>
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaluationWay_wallOpenArea == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallOpenArea == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallOpenArea == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallOpenArea == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallOpenArea == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallOpenArea == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_wallOpenArea == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                      <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_wallOpenArea">{{formtable.evaInstitu_wallOpenArea}}</span>
                     <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">外遮阳&nbsp;</td>
                    <td colspan="4">
                     <span v-if="formtable.outShade">{{formtable.outShade}}</span>
                     <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaluationWay_outShade == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_outShade == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_outShade == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_outShade == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_outShade == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_outShade == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_outShade == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                        <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_outShade">{{formtable.evaInstitu_outShade}}</span>
                     <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      :rowspan="
                        19 +
                        jizu.length +
                        jizu2.length +
                        jizu3.length +
                        jizu4.length
                      "
                      scope="row"
                      style="background-color:#a3c1dd2d"
                    >
                      &nbsp;冷 热 源 及 空 调 系 统
                    </td>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">&nbsp; 空调冷源</td>
                    <td colspan="4">
                      <span v-if="formtable.coldSource">{{formtable.coldSource}}</span>
                         <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaluationWay_coldSource == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSource == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSource == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSource == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSource == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSource == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSource == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                      <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_coldSource">{{formtable.evaInstitu_coldSource}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">供暖热源&nbsp;</td>
                    
                    <td colspan="4">
                     <span v-if="formtable.hotSource">{{formtable.hotSource}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                           <span v-if="formtable.evaluationWay_hotSource == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotSource == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotSource == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotSource == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotSource == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotSource == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotSource == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_hotSource"></span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" rowspan="2" scope="row" style="background-color:#a3c1dd2d">&nbsp; 锅炉</td>
                    <td colspan="2" style="background-color:#a3c1dd2d">类型&nbsp;</td>
                    <td colspan="2" style="background-color:#a3c1dd2d">&nbsp; 额定热效率（%）</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row">
                      <span v-if="formtable.boilerType">{{formtable.boilerType}}</span>
                       <span v-else>——</span>
                    </td>
                    <td colspan="2">
                      <span v-if="formtable.rateEffic">{{formtable.rateEffic}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                          <span v-if="formtable.evaluationWay_rateEffic == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_rateEffic == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_rateEffic == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_rateEffic == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_rateEffic == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_rateEffic == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_rateEffic == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_rateEffic">{{formtable.evaInstitu_rateEffic}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td :rowspan="jizu.length + 1" colspan="2" scope="row" style="background-color:#a3c1dd2d">
                      冷水（热泵）机组
                      <div class="zengjia">
                        <!-- <el-button type="primary" @click="addjizu(1)">
                        增加 冷水（热泵）机组
                      </el-button> -->
                      </div>
                    </td>
                    <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
                    <td style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量（kW）</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;台数</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;性能系数（COP）</td>
                    <td></td>
                    <td></td>
                  </tr>
                     <tr v-for="(item, index) in jizu" :key="item">
              <td scope="row">
                <span v-if="item.unitType">{{item.unitType}}</span>
                <span v-else>——</span>
              </td>
              <td>
               <span v-if="item.unitMeasure">{{item.unitMeasure}}</span>
               <span v-else>——</span>
              </td>
              <td>
               <span v-if="item.unitNum">{{item.unitNum}}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitErr">{{item.unitErr}}</span>
                 <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu.length">
               <span v-if="formtable.evaluationWay_indoorTem == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
              </td>
              <td  v-if="index == 0" :rowspan="jizu.length">
                <span v-if="item.evaInstitu">{{item.evaInstitu}}</span>
                 <span v-else>——</span>
              </td>
            </tr>
                  <tr>
                    <td :rowspan="jizu2.length + 1" colspan="2" scope="row" style="background-color:#a3c1dd2d">
                      单元式空气调节机、风管送风式和屋顶调节机组
                      <div class="zengjia">
                        <!-- <el-button type="primary" @click="addjizu(2)">
                        单元式空气调节机、风管送风式和屋顶调节机组
                      </el-button> -->
                      </div>
                    </td>
                    <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
                    <td style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量（kW）</td>
                    <td style="background-color:#a3c1dd2d">&nbsp; 台数</td>
                    <td style="background-color:#a3c1dd2d"> &nbsp;能效比（EER）</td>
                    <td></td>
                    <td></td>
                  </tr>
                        <tr v-for="(item, index) in jizu2" :key="item">
              <td scope="row">
               <span v-if="item.unitType">{{item.unitType}}</span>
                <span v-else>——</span>
              </td>
              <td>
               <span v-if="item.unitMeasure">{{item.unitMeasure}}</span>
                <span v-else>——</span>
              </td>
              <td>
               <span v-if="item.unitNum">{{item.unitNum}}</span>
               <span v-else>——</span>
              </td>
              <td>
               <span v-if="item.unitErr">{{item.unitErr}}</span>
               <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu2.length">
                 <span v-if="formtable.evaluationWay_indoorTem == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu2.length">
               <span v-if="item.evaInstitu">{{item.evaInstitu}}</span>
               <span v-else>——</span>
              </td>
            </tr>
                  <!-- <tr>
            <th scope="col" colspan="8" style="height:40px">测评内容&nbsp;</th>
            <th scope="col">测评方法&nbsp;</th>
            <th scope="col">&nbsp;测评结果</th>

          </tr> -->
                  <tr>
                    <!-- <th scope="row" :rowspan="18+jizu3.length+jizu4.length">&nbsp;规定项</th> -->
                    <!-- <td  :rowspan="13+jizu3.length+jizu4.length">冷热源及空调系统</td> -->
                    <td :rowspan="jizu3.length + 1" colspan="2" style="background-color:#a3c1dd2d">
                      多联式空调（热泵）机组
                      <div class="zengjia">
                        <!-- <el-button type="primary" @click="addjizu(3)">
                        增加 多联式空调（热泵）机组
                      </el-button> -->
                      </div>
                    </td>
                    <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;单机额定制冷量(kW)</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;台数</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;综合性能系数(IPLV)</td>
                    <td></td>
                    <td></td>
                  </tr>
               <tr v-for="(item, index) in jizu3" :key="item">
              <td scope="row">
                <span v-if="item.unitType">{{item.unitType}}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitMeasure">{{item.unitMeasure}}</span>
                 <span v-else>——</span>
              </td>
              <td>
               <span v-if="item.unitNum">{{item.unitNum}}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitErr">{{item.unitErr}}</span>
                 <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu3.length">
                 <span v-if="formtable.evaluationWay_indoorTem == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu3.length">
               <span v-if="item.evaInstitu">{{item.evaInstitu}}</span>
                <span v-else>——</span>
              </td>
            </tr>
                  <tr>
                    <td :rowspan="jizu4.length + 1" colspan="2" scope="row" style="background-color:#a3c1dd2d">
                      溴化锂吸收式机组
                      <div class="zengjia">
                        <!-- <el-button type="primary" @click="addjizu(4)">
                        增加溴化锂吸收式机组
                      </el-button> -->
                      </div>
                    </td>
                    <td style="background-color:#a3c1dd2d">&nbsp;类型</td>
                    <td style="background-color:#a3c1dd2d">&nbsp; 单机额定制冷量(kW)</td>
                    <td style="background-color:#a3c1dd2d">&nbsp; 台数</td>
                    <td style="background-color:#a3c1dd2d">&nbsp; 综合性能系数(IPLV)</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr v-for="(item, index) in jizu4" :key="item">
              <td scope="row">
               <span v-if="item.unitType">{{item.unitType}}</span>
                <span v-else>——</span>
              </td>
              <td>
               <span v-if="item.unitMeasure">{{item.unitMeasure}}</span>
                <span v-else>——</span>
              </td>
              <td>
               <span v-if="item.unitNum">{{item.unitNum}}</span>
               <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitErr">{{item.unitErr}}</span>
                <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu4.length">
              <span v-if="formtable.evaluationWay_indoorTem == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu4.length">
                <span v-if="item.evaInstitu">{{item.evaInstitu}}</span>
                <span v-else>——</span>
              </td>
            </tr>

                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">室内温度</td>
                    <td colspan="4">
                     <span v-if="formtable.indoorTem">{{formtable.indoorTem}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                         <span v-if="formtable.evaluationWay_indoorTem == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorTem == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_indoorTem">{{formtable.evaInstitu_indoorTem}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">新风量&nbsp;</td>
                    <td colspan="4">
                     <span v-if="formtable.windNum">{{formtable.windNum}}</span>
                    </td>
                    <td>
                         <span v-if="formtable.evaluationWay_windNum == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_windNum == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_windNum == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_windNum == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_windNum == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_windNum == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_windNum == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                    <span v-if="formtable.evaInstitu_windNum">{{formtable.evaInstitu_windNum}}</span>
                    <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">
                      集中供暖系统热水循环水泵的耗电输热比
                    </td>
                    <td colspan="4">
                     <span v-if="formtable.focusRatio">{{formtable.focusRatio}}</span>
                     <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_focusRatio == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_focusRatio == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_focusRatio == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_focusRatio == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_focusRatio == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_focusRatio == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_focusRatio == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_focusRatio">{{formtable.evaInstitu_focusRatio}}</span>
                       <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">风机单位风量耗功率</td>
                    <td colspan="4">
                      <span v-if="formtable.windPower">{{formtable.windPower}}</span>
                       <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_windPower == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_windPower == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_windPower == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_windPower == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_windPower == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_windPower == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_windPower == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_windPower">{{formtable.evaInstitu_windPower}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">空调水系统耗电输热比</td>
                    <td colspan="4">
                      <span v-if="formtable.airRatio">{{formtable.airRatio}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_airRatio == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_airRatio == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airRatio == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airRatio == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airRatio == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airRatio == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_airRatio == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_airRatio">{{formtable.evaInstitu_airRatio}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">室温调节设施</td>
                    <td colspan="4">
                     <span v-if="formtable.indoorFacil">{{formtable.indoorFacil}}</span>
                     <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_indoorFacil == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorFacil == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorFacil == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorFacil == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorFacil == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorFacil == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_indoorFacil == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_indoorFacil">{{formtable.evaInstitu_indoorFacil}}</span>
                       <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="3" scope="row" style="background-color:#a3c1dd2d">计量装置</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;集中供暖系统</td>
                    <td colspan="4">
                      <span v-if="formtable.facusSystem">{{formtable.facusSystem}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_facusSystem == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_facusSystem == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_facusSystem == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_facusSystem == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_facusSystem == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_facusSystem == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_facusSystem == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_facusSystem">{{formtable.evaInstitu_facusSystem}}</span>
                     <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" style="background-color:#a3c1dd2d">区域冷/热源&nbsp;</td>
                    <td colspan="4">
                      <span v-if="formtable.regionSource">{{formtable.regionSource}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_regionSource == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_regionSource == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_regionSource == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_regionSource == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_regionSource == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_regionSource == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_regionSource == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_regionSource">{{formtable.evaInstitu_regionSource}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" style="background-color:#a3c1dd2d">制冷站空调系统&nbsp;</td>
                    <td colspan="4">
                      <span v-if="formtable.coldSystem">{{formtable.coldSystem}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_coldSystem == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSystem == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSystem == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSystem == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSystem == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSystem == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldSystem == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_coldSystem">{{formtable.evaInstitu_coldSystem}}</span>
                     <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">水力平衡</td>
                    <td colspan="4">
                     <span v-if="formtable.waterBalence">{{formtable.waterBalence}}</span>
                     <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_waterBalence == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_waterBalence == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_waterBalence == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_waterBalence == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_waterBalence == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_waterBalence == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_waterBalence == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_waterBalence">{{formtable.evaInstitu_waterBalence}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">监测和控制系统</td>
                    <td colspan="4">
                      <span v-if="formtable.detectSystem">{{formtable.detectSystem}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_detectSystem == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_detectSystem == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_detectSystem == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_detectSystem == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_detectSystem == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_detectSystem == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_detectSystem == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_detectSystem">{{formtable.evaInstitu_detectSystem}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="3" scope="row" style="background-color:#a3c1dd2d">照明系统</td>
                    <td colspan="2" style="background-color:#a3c1dd2d">&nbsp;照明功率密度</td>
                    <td colspan="4">
                     <span v-if="formtable.lightDensity">{{formtable.lightDensity}}</span>
                     <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_lightDensity == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightDensity == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightDensity == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightDensity == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightDensity == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightDensity == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightDensity == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_lightDensity">{{formtable.evaInstitu_lightDensity}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">照度的时序自动控制装置</td>
                    <td colspan="4">
                     <span v-if="formtable.lightAuto">{{formtable.lightAuto}}</span>
                     <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_lightAuto == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightAuto == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightAuto == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightAuto == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightAuto == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightAuto == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightAuto == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_lightAuto">{{formtable.evaInstitu_lightAuto}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">&nbsp;降低照度控制措施</td>
                    <td colspan="4">
                      <span v-if="formtable.lowerlight">{{formtable.lowerlight}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                         <span v-if="formtable.evaluationWay_lowerlight == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_lowerlight == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lowerlight == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lowerlight == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lowerlight == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lowerlight == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_lowerlight == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_lowerlight">{{formtable.evaInstitu_lowerlight}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2" scope="row" style="background-color:#a3c1dd2d">生活热水系统</td>
                    <td colspan="2" style="background-color:#a3c1dd2d">&nbsp;热泵性能</td>
                    <td colspan="4">
                      <span v-if="formtable.heatPump">{{formtable.heatPump}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_heatPump == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatPump == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatPump == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatPump == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatPump == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatPump == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_heatPump == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_heatPump">{{formtable.evaInstitu_heatPump}}</span>
                     <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" scope="row" style="background-color:#a3c1dd2d">热水监控&nbsp;</td>
                    <td colspan="4">
                      <span v-if="formtable.hotMonitor">{{formtable.hotMonitor}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                          <span v-if="formtable.evaluationWay_hotMonitor == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotMonitor == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotMonitor == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotMonitor == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotMonitor == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotMonitor == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_hotMonitor == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_hotMonitor">{{formtable.evaInstitu_hotMonitor}}</span>
                     <span v-else>——</span>
                    </td>
                  </tr>
                  <!-- <tr>
                <th scope="col" colspan="8" style="height:40px">&nbsp;测评内容</th>
                <th scope="col">&nbsp;测评方法</th>
                <th scope="col">&nbsp;测评结果</th>
              </tr> -->
                  <tr>
                    <th rowspan="19" scope="row" style="background-color:#a3c1dd2d">选择项&nbsp;</th>
                    <td rowspan="5" style="background-color:#a3c1dd2d">&nbsp;可再生能源</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;太阳能热水系统</td>
                    <td style="background-color:#a3c1dd2d">集热效率&nbsp;</td>
                    <td colspan="4">
                      <span v-if="formtable.gatherHot">{{formtable.gatherHot}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_gatherHot == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_gatherHot == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_gatherHot == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_gatherHot == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_gatherHot == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_gatherHot == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_gatherHot == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_gatherHot">{{formtable.evaInstitu_gatherHot}}</span>
                     <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" style="background-color:#a3c1dd2d">可再生能源发电系统&nbsp;</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
                    <td colspan="4">
                     <span v-if="formtable.renewRatio">{{formtable.renewRatio}}</span>
                     <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_renewRatio == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_renewRatio == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_renewRatio == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_renewRatio == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_renewRatio == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_renewRatio == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_renewRatio == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_renewRatio"></span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" style="background-color:#a3c1dd2d">太阳能光伏系统&nbsp;</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;光电转换效率</td>
                    <td colspan="4">
                      <span v-if="formtable.lightEffic">{{formtable.lightEffic}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                         <span v-if="formtable.evaluationWay_lightEffic == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightEffic == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightEffic == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightEffic == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightEffic == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightEffic == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightEffic == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_lightEffic">{{formtable.evaInstitu_lightEffic}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" style="background-color:#a3c1dd2d">&nbsp;地源热泵系统</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
                    <td colspan="4">
                      <span v-if="formtable.earthRatio">{{formtable.earthRatio}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_earthRatio == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_earthRatio == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_earthRatio == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_earthRatio == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_earthRatio == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_earthRatio == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_earthRatio == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_earthRatio">{{formtable.evaInstitu_earthRatio}}</span>
                       <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" style="background-color:#a3c1dd2d">空气源热泵&nbsp;</td>
                    <td style="background-color:#a3c1dd2d">&nbsp;比例</td>
                    <td colspan="4">
                      <span v-if="formtable.airPump">{{formtable.airPump}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_airPump == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_airPump == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airPump == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airPump == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airPump == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airPump == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_airPump == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_airPump">{{formtable.evaInstitu_airPump}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;自然通风</td>
                    <td colspan="4">
                      <span v-if="formtable.natureWind">{{formtable.natureWind}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_natureWind == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureWind == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureWind == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureWind == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureWind == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureWind == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureWind == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_natureWind">{{formtable.evaInstitu_natureWind}}</span>
                     <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;自然采光</td>
                    <td colspan="4">
                      <span v-if="formtable.natureLight">{{formtable.natureLight}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_natureLight == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureLight == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureLight == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureLight == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureLight == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureLight == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_natureLight == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_natureLight">{{formtable.evaInstitu_natureLight}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;遮阳措施</td>
                    <td colspan="4">
                     <span v-if="formtable.lightMeasure">{{formtable.lightMeasure}}</span>
                     <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_lightMeasure == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightMeasure == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightMeasure == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightMeasure == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightMeasure == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightMeasure == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_lightMeasure == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_lightMeasure">{{formtable.evaInstitu_lightMeasure}}</span>
                       <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;能量回放</td>
                    <td colspan="4">
                      <span v-if="formtable.powerBack">{{formtable.powerBack}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_powerBack == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_powerBack == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_powerBack == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_powerBack == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_powerBack == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_powerBack == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_powerBack == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_powerBack">{{formtable.evaInstitu_powerBack}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;蓄冷蓄热技术</td>
                    <td colspan="4">
                      <span v-if="formtable.saveSkill">{{formtable.saveSkill}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_saveSkill == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_saveSkill == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_saveSkill == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_saveSkill == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_saveSkill == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_saveSkill == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_saveSkill == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_saveSkill">{{formtable.evaInstitu_saveSkill}}</span>
                     <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">冷凝热利用&nbsp;</td>
                    <td colspan="4">
                     <span v-if="formtable.coldHotUse">{{formtable.coldHotUse}}</span>
                     <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_coldHotUse == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldHotUse == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldHotUse == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldHotUse == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldHotUse == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldHotUse == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_coldHotUse == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_coldHotUse">{{formtable.evaInstitu_coldHotUse4}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;全新风/可变新风比</td>
                    <td colspan="4">
                      <span v-if="formtable.newWind">{{formtable.newWind}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_newWind == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_newWind == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_newWind == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_newWind == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_newWind == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_newWind == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_newWind == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_newWind">{{formtable.evaInstitu_newWind}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;变流量/变风量</td>
                    <td colspan="4">
                      <span v-if="formtable.changeWind">{{formtable.changeWind}}</span>
                       <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_changeWind == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeWind == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeWind == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeWind == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeWind == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeWind == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeWind == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_changeWind">{{formtable.evaInstitu_changeWind}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">供回水温差&nbsp;</td>
                    <td colspan="4">
                      <span v-if="formtable.changeTem">{{formtable.changeTem}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                       <span v-if="formtable.evaluationWay_changeTem == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeTem == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeTem == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeTem == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeTem == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeTem == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_changeTem == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_changeTem">{{formtable.evaInstitu_changeTem}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;楼宇自控</td>
                    <td colspan="4">
                     <span v-if="formtable.buildControl">{{formtable.buildControl}} </span>
                     <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_buildControl == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_buildControl == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_buildControl == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_buildControl == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_buildControl == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_buildControl == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_buildControl == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_buildControl">{{formtable.evaInstitu_buildControl}}</span>
                     <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;计量统计+节能控制措施</td>
                    <td colspan="4">
                     <span v-if="formtable.energyControl">{{formtable.energyControl}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                         <span v-if="formtable.evaluationWay_energyControl == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyControl == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyControl == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyControl == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyControl == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyControl == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyControl == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>

                    <td>
                     <span v-if="formtable.evaInstitu_energyControl">{{formtable.evaInstitu_energyControl}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">冷热源设备能效等级&nbsp;</td>
                    <td colspan="4">
                      <span v-if="formtable.energyGrade">{{formtable.energyGrade}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                         <span v-if="formtable.evaluationWay_energyGrade == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyGrade == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyGrade == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyGrade == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyGrade == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyGrade == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_energyGrade == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_energyGrade">{{formtable.evaInstitu_energyGrade}}</span>
                       <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;风扇调风</td>
                    <td colspan="4">
                      <span v-if="formtable.airCond">{{formtable.airCond}}</span>
                      <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_airCond == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_airCond == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airCond == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airCond == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airCond == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_airCond == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_airCond == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                      <span v-if="formtable.evaInstitu_airCond">{{formtable.evaInstitu_airCond}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" scope="row" style="background-color:#a3c1dd2d">&nbsp;其他新型节能措施</td>
                    <td colspan="4">
                     <span v-if="formtable.otherMeasure">{{formtable.otherMeasure}}</span>
                     <span v-else>——</span>
                    </td>
                    <td>
                        <span v-if="formtable.evaluationWay_otherMeasure == 1"
                        >软件评估</span
                      >
                      <span v-else-if="formtable.evaluationWay_otherMeasure == 2"
                        >文件审查</span
                      >
                      <span v-else-if="formtable.evaluationWay_otherMeasure == 3"
                        >现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_otherMeasure == 4"
                        >性能检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_otherMeasure == 5"
                        >文件审查；现场检查</span
                      >
                      <span v-else-if="formtable.evaluationWay_otherMeasure == 6"
                        >文件审查；性能检测</span
                      >
                      <span v-else-if="formtable.evaluationWay_otherMeasure == 7"
                        >文件审查；现场检查；性能检测</span
                      >
                       <span v-else>——</span>
                    </td>
                    <td>
                     <span v-if="formtable.evaInstitu_otherMeasure">{{formtable.evaInstitu_otherMeasure}}</span>
                      <span v-else>——</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="10" scope="row">
                      <div class="minyong">
                        <p>1、民用建筑能效测评结论：</p>
                        <p>
                          （1）经软件模拟该项目基础相对节能率为<input
                            v-model="formtable.relateEnergy"
                            type="text"
                          />%，(设计标准为
                          <span class="minyong_select">
                            <span v-if="formtable.designStand == 1"
                              >《建筑节能与可再生能源利用通用规范》GB 55015-2021</span
                            >
                            <span v-if="formtable.designStand == 2"
                              >《公共建筑节能设计标准》DGJ32J96-2010</span
                            >
                            <span v-if="formtable.designStand == 3"
                              >《公共建筑节能设计标准》GB50189-2015</span
                            >
                          </span>
                          <span> ); </span>
                        </p>
                        <p>
                          （2）经测评，该项目规定项<input
                            v-model="formtable.strip"
                            type="text"
                          />条参评，均满足《民用建筑能效测评标识标准》DB32/T
                          3964-2020规定要求；
                        </p>
                        <p>
                          （3）经测评，该项目选择项<input
                            v-model="formtable.selectStrip"
                            type="text"
                          />条参评，加分<input
                            v-model="formtable.grades"
                            type="text"
                          />分；
                        </p>
                        <p>
                          （4）经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                          3964-2020标准要求，建筑节能率为<input
                            v-model="formtable.buildEnergyRatio"
                            type="text"
                          />%，测评合格。
                        </p>
                        <p>2、民用建筑能效标识建议：</p>
                        <p>
                          依据民用建筑能效测评结论，建议该建筑能效标识为
                          <span v-if="project.stars==1">一</span>
                          <span v-if="project.stars==2">二</span>
                          <span v-if="project.stars==3">三</span>
                          星。
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4" scope="row">测评人员&nbsp;</th>
                    <th colspan="4">&nbsp;审核人员</th>
                    <th colspan="4">&nbsp;批准人员</th>
                  </tr>
                  <tr class="table_select">
                    <th colspan="4">
                      {{formtable.evaluatUser}}
                    </th>
                    <th colspan="4">
                      {{formtable.auditUser}}
                    </th>
                    <th colspan="4">
                      {{formtable.approveUser}}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-form>
    </div>
</template>
<script>
import {getStaff} from "../../../../../api/user";
import {checkSave, projectTableInfo} from "../../../../../api/project";

export default {

  data(){
    return {
      formtable: {},
      project: {},
      //冷水热泵机组
      jizu: [],
      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],
      jizu3: [],
      jizu4: [],
      jizu5: [],
      jizu6: [],
      staffList: [],
      staffList2: [],
      staffList3: [],
      staffPage: 2,
      staffPage2: 2,
      staffPage3: 2,
      activeStaff: 1,
      edit: true,
      //1是从形式审查调过来的，2是从技术审查跳过来的
      from: 1,
      shencha1: [],
    }
  },

  mounted() {
    this.project_id = this.$route.query.project_id;
    this.getAllStaffList();
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    this.getInfo();
  },


  methods:{
    //获得员工信息
    getStaffList(val = "") {
      console.log(this.activeStaff);
      let that = this;
      let params = {
        token: this.$store.state.token,
        limit: 1000,
      };
      if (this.activeStaff == 1) {
        params.page = this.staffPage;
      } else if (this.activeStaff == 2) {
        params.page = this.staffPage2;
      } else {
        params.page = this.staffPage3;
      }
      if (val != "") {
        params.name = val;
      }
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          if (this.activeStaff == 1) {
            that.staffList = that.staffList.concat(res.data);
            that.staffPage += 1;
          } else if (this.activeStaff == 2) {
            that.staffList2 = that.staffList2.concat(res.data);
            that.staffPage2 += 1;
          } else {
            that.staffList3 = that.staffList3.concat(res.data);
            that.staffPage3 += 1;
          }
        }
      });
    },
    check() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$message.success("保存成功");
        // that.goBack();
      });
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.project = res.data;
        if (that.project.status > 8) {
          that.edit = false;
        }
        let checks = res.data.checks;
        that.shencha1 = checks.map(item => {
          if (item.type == 2) {
            return item;
          }
        }).filter(d => d);
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 2) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
        that.formtable = that.project.detail;
        if (!that.formtable) {
          that.formtable = {};
        }
        let jizu_total = that.project.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2);
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
        that.$nextTick(function () {
          let table = document.getElementById("engerytable");
          let inputs = table.getElementsByTagName("input");
          for (let i = 0; i < inputs.length; i++) {
            inputs[i].disabled = true;
          }
        });
      });
    },

    pre_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$router.push({
          path: "/formal/formDetail?project_id=" + this.project_id,
        });
      });
    },
    next_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$router.push({
          path: "/formal/reportTable?project_id=" + this.project_id,
        });
      });
    },
    getAllStaffList() {
      let params = {
        token: this.$store.state.token,
        limit: 1000,
        page: 1,
      };
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          this.staffList = res.data;
          this.staffList2 = res.data;
          this.staffList3 = res.data;
        }
      });
    },
    /**
     * 筛选企业
     */
    fliterStaff(searchVlaue) {
      this.staffList = [];
      this.staffPage = 1;
      this.getStaffList(searchVlaue);
    },
    //select聚焦
    selectFocus(active) {
      this.activeStaff = active;
      this.getStaffList();
    },
  }

}
</script>
<style scoped>
th{
  background-color: #c3d8eebd;
}
/deep/ .el-textarea > textarea {
  height: 513px;
}

/deep/ .table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
</style>
<style scoped>
.minyong p{
  text-align: left !important;
}
.engerytable td {
  height: 40px;
  text-align: center;
  font-size: 14px;
  word-wrap:break-word;
  word-break:break-all;
}
.engerytable > table > tbody > tr > td:nth-child(3) {
  text-align: center;
}

.engerytable > table > tbody > tr > td:nth-child(4) {
  text-align: center;
}

.engerytable > table > tbody > tr > td:nth-child(6) {
  text-align: center;
}

.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}
</style>
