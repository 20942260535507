<template>
  <div>
    <div class="tableform">
      <el-form ref="form" :model="project" label-width="180px">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="项目名称：">
                  {{ project.name }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="建筑面积（m²）/层数：">
                  {{ project.building_area }}/{{ project.floor }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="建设单位：">
                  {{ project.build_company }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="施工单位：">
                  {{ project.work_build }}
                </el-form-item>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi">
                <el-form-item label="项目地址：">
                  {{ project.address }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="气候区域：">
                  <span v-if="project.weather == 1">夏热冬冷</span>
                  <span v-if="project.weather == 2">寒冷</span>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="设计单位：">
                  {{ project.design_company }}
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="监理单位：">
                  {{ project.manger_company }}
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-form ref="formtable" :model="formtable" label-width="180px">
      <div id="engerytable" class="engerytable">
        <table border="1" cellspacing="0" width="100%">
          <tbody>
            <tr>
              <th colspan="8" scope="col" style="height: 40px">
                &nbsp;测评内容
              </th>
              <th scope="col">&nbsp;测评方法</th>
              <th scope="col">&nbsp;测评结果</th>
            </tr>
            <tr>
              <th rowspan="3" scope="row">基础类&nbsp;</th>
              <td colspan="3" style="background-color: #a3c1dd2d">
                单位建筑面积供暖能耗（kWh/m2）
              </td>
              <td colspan="2">
                <span v-if="formtable.heatEnergy">{{
                  formtable.heatEnergy
                }}</span>
                <span v-else>——</span>
              </td>
              <td colspan="2" rowspan="3" style="background-color: #a3c1dd2d">
                &nbsp; 相对节能率（%）
              </td>
              <td rowspan="3">
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td rowspan="3">
                <span v-if="formtable.evaInstitu_heatEnergy">{{
                  formtable.evaInstitu_heatEnergy
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                单位建筑面积空调能耗（kWh/m2）
              </td>
              <td colspan="2">
                <span v-if="formtable.airEnergy">{{
                  formtable.airEnergy
                }}</span>
                <span v-else>——</span>
              </td>
              <!-- <td>
                   <el-select v-model="formtable.evaluationWay_airEnergy" placeholder="选择测评方法" clearable>
                   <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                  </el-select>

              </td> -->
              <!-- <td>
                <input type="text" v-model="formtable.evaInstitu_airEnergy" placeholder="——" />
              </td> -->
            </tr>
            <tr>
              <td colspan="3" scope="row" style="background-color: #a3c1dd2d">
                单位建筑面积全年能耗量（kWh/m² )
              </td>
              <td colspan="2">
                <span v-if="formtable.buildEnergy">{{
                  formtable.buildEnergy
                }}</span>
                <span v-else>——</span>
              </td>
              <!-- <td>
                      <el-select v-model="formtable.evaluationWay_buildEnergy" placeholder="选择测评方法" clearable>
                  <el-option value="1" label="软件评估"></el-option>
                  <el-option value="2" label="文件审查"></el-option>
                  <el-option value="3" label="现场检查"></el-option>
                  <el-option value="4" label="性能检查"></el-option>
                  <el-option value="5" label="文件审查；现场检查"></el-option>
                  <el-option value="6" label="文件审查；性能检测"></el-option>
                  <el-option
                    value="7"
                    label="文件审查；现场检查；性能检测"
                  ></el-option>
                  </el-select>
              </td>
              <td>
                <input type="text" v-model="formtable.evaInstitu_buildEnergy" placeholder="——"/>
              </td> -->
            </tr>
            <tr>
              <th
                :rowspan="
                  30 + jizu5.length + jizu.length + jizu3.length + jizu6.length
                "
                scope="row"
              >
                &nbsp;规定项
              </th>
              <th rowspan="5" style="background-color: #a3c1dd2d">
                &nbsp;围护结构
              </th>
              <td colspan="2" style="background-color: #a3c1dd2d">
                外窗气密性
              </td>
              <td colspan="4">
                <span v-if="formtable.wallGas">{{ formtable.wallGas }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_wallGas">{{
                  formtable.evaInstitu_wallGas
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" style="background-color: #a3c1dd2d">
                热桥部位（夏热冬冷/寒冷地区）
              </td>
              <td colspan="4">
                <span v-if="formtable.hotPart">{{ formtable.hotPart }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_hotPart">{{
                  formtable.evaInstitu_hotPart
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                门窗保温（夏热冬冷/寒冷地区）
              </td>
              <td colspan="4">
                <span v-if="formtable.doorHeat">{{ formtable.doorHeat }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_doorHeat">{{
                  formtable.evaInstitu_doorHeat
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                建筑外窗（夏热冬冷/寒冷地区）
              </td>
              <td colspan="4">
                <span v-if="formtable.wallOpenArea">{{
                  formtable.wallOpenArea
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_wallOpenArea">{{
                  formtable.evaInstitu_wallOpenArea
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                南向外遮阳
              </td>
              <td colspan="4">
                <span v-if="formtable.outShade">{{ formtable.outShade }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_outShade">{{
                  formtable.evaInstitu_outShade
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td
                :rowspan="
                  6 + jizu5.length + jizu.length + jizu3.length + jizu6.length
                "
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                &nbsp;冷 热 源 及 空 调 系 统
              </td>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                &nbsp; 空调冷源
              </td>
              <td colspan="4">
                <span v-if="formtable.coldSource">{{
                  formtable.coldSource
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_coldSource">{{
                  formtable.evaInstitu_coldSource
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" scope="row" style="background-color: #a3c1dd2d">
                供暖热源&nbsp;
              </td>
              <td colspan="4">
                <span v-if="formtable.hotSource">{{
                  formtable.hotSource
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_hotSource">{{
                  formtable.evaInstitu_hotSource
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                :rowspan="jizu5.length + 1"
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                房间空气调节器
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(1)">
                    增加 房间空气调节器
                  </el-button>
                </div>
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;类型</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp; 单机额定制冷量（kW）
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;台数</td>
              <td style="background-color: #a3c1dd2d">&nbsp;性能系数（COP）</td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu5" :key="index">
              <td scope="row">
                <span v-if="item.unitType">{{ item.unitType }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitMeasure">{{ item.unitMeasure }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitNum">{{ item.unitNum }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitErr">{{ item.unitErr }}</span>
                <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu5.length">
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>

              <td v-if="index == 0" :rowspan="jizu5.length">
                <span v-if="item.evaInstitu">{{ item.evaInstitu }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                :rowspan="jizu.length + 1"
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                冷水（热泵）机组
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(2)">
                    增加 冷水（热泵）机组
                  </el-button>
                </div>
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;类型</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp; 单机额定制冷量（kW）
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;台数</td>
              <td style="background-color: #a3c1dd2d">&nbsp;性能系数（COP）</td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu" :key="index">
              <td scope="row">
                <span v-if="item.unitType">{{ item.unitType }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitMeasure">{{ item.unitMeasure }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitNum">{{ item.unitNum }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitErr">{{ item.unitErr }}</span>
                <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu.length">
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>

              <td v-if="index == 0" :rowspan="jizu.length">
                <span v-if="item.evaInstitu">{{ item.evaInstitu }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td
                :rowspan="jizu3.length + 1"
                colspan="2"
                style="background-color: #a3c1dd2d"
              >
                多联式空调（热泵）机组
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(3)">
                    增加 多联式空调（热泵）机组
                  </el-button>
                </div>
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;类型</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp;单机额定制冷量(kW)
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;台数</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp;综合性能系数(IPLV)
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu3" :key="index">
              <td scope="row">
                <span v-if="item.unitType">{{ item.unitType }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitMeasure">{{ item.unitMeasure }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitNum">{{ item.unitNum }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitErr">{{ item.unitErr }}</span>
                <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu3.length">
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu3.length">
                <span v-if="item.evaInstitu">{{ item.evaInstitu }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                :rowspan="jizu6.length + 1"
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                转速可控型房间空调器
                <div class="zengjia">
                  <el-button type="primary" @click="addjizu(4)">
                    增加转速可控型房间空调器
                  </el-button>
                </div>
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;类型</td>
              <td style="background-color: #a3c1dd2d">
                &nbsp; 单机额定制冷量（kW）
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp; 台数</td>
              <td style="background-color: #a3c1dd2d">&nbsp;能效比（EER）</td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="(item, index) in jizu6" :key="index">
              <td scope="row">
                <span v-if="item.unitType">{{ item.unitType }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitMeasure">{{ item.unitMeasure }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitNum">{{ item.unitNum }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="item.unitErr">{{ item.unitErr }}</span>
                <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu6.length">
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td v-if="index == 0" :rowspan="jizu6.length">
                <span v-if="item.evaInstitu">{{ item.evaInstitu }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td rowspan="11" scope="row" style="background-color: #a3c1dd2d">
                集中冷热源与管网
              </td>
              <td
                colspan="2"
                rowspan="2"
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                &nbsp; 锅炉
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                类型&nbsp;
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                &nbsp; 额定热效率（%）
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2" scope="row">
                <span v-if="formtable.boilerType">{{
                  formtable.boilerType
                }}</span>
                <span v-else>——</span>
              </td>
              <td colspan="2">
                <span v-if="formtable.rateEffic">{{
                  formtable.rateEffic
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_rateEffic">{{
                  formtable.evaInstitu_rateEffic
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                rowspan="2"
                scope="row"
                style="background-color: #a3c1dd2d"
              >
                户式燃气炉
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                类型&nbsp;
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                &nbsp; 额定热效率（%）
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2" scope="row">
                <span v-if="formtable.houseType">{{
                  formtable.houseType
                }}</span>
                <span v-else>——</span>
              </td>
              <td colspan="2">
                <span v-if="formtable.houseEffic">{{
                  formtable.houseEffic
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_houseEffic">{{
                  formtable.evaInstitu_houseEffic
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                集中供暖系统热水循环水泵的耗电输热比
              </td>
              <td colspan="4">
                <span v-if="formtable.focusRatio">{{
                  formtable.focusRatio
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_focusRatio">{{
                  formtable.evaInstitu_focusRatio
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                热量表
              </td>
              <td colspan="4">
                <span v-if="formtable.heatMeater">{{
                  formtable.heatMeater
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_heatMeater">{{
                  formtable.evaInstitu_heatMeater
                }}</span
                ><span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                分户温控及计量
              </td>
              <td colspan="4">
                <span v-if="formtable.temMeasure">{{
                  formtable.temMeasure
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_temMeasure">{{
                  formtable.evaInstitu_temMeasure
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                水力平衡&nbsp;
              </td>
              <td colspan="4">
                <span v-if="formtable.waterBalence">{{
                  formtable.waterBalence
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_waterBalence">{{
                  formtable.evaInstitu_waterBalence
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                自动控制装置
              </td>
              <td colspan="4">
                <span v-if="formtable.autoControl">{{
                  formtable.autoControl
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_autoControl">{{
                  formtable.evaInstitu_autoControl
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                自动监测与控制
              </td>
              <td colspan="4">
                <span v-if="formtable.detectSystem">{{
                  formtable.detectSystem
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_detectSystem">{{
                  formtable.evaInstitu_detectSystem
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                供热量控制
              </td>
              <td colspan="4">
                <span v-if="formtable.heatControl">{{
                  formtable.heatControl
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_heatControl">{{
                  formtable.evaInstitu_heatControl
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" rowspan="6" style="background-color: #a3c1dd2d">
                供暖与空调系统
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                &nbsp;不应采用直接电热供暖/冷
              </td>
              <td colspan="4">
                <span v-if="formtable.noDirectHeat">{{
                  formtable.noDirectHeat
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_noDirectHeat">{{
                  formtable.evaInstitu_noDirectHeat
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                冷/热水系统的输送能效比
              </td>
              <td colspan="4">
                <span v-if="formtable.waterTransAutio">{{
                  formtable.waterTransAutio
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_waterTransAutio">{{
                  formtable.evaInstitu_waterTransAutio
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                空调冷热源机房的自动控制系统
              </td>
              <td colspan="4">
                <span v-if="formtable.airAutoSystem">{{
                  formtable.airAutoSystem
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_airAutoSystem">{{
                  formtable.evaInstitu_airAutoSystem
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                分时分室控制设施
              </td>
              <td colspan="4">
                <span v-if="formtable.apartMeasure">{{
                  formtable.apartMeasure
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_apartMeasure">{{
                  formtable.evaInstitu_apartMeasure
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                分户热量计量装置
              </td>
              <td colspan="4">
                <span v-if="formtable.apartHeatUnit">{{
                  formtable.apartHeatUnit
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_apartHeatUnit">{{
                  formtable.evaInstitu_apartHeatUnit
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="2" style="background-color: #a3c1dd2d">
                水力平衡措施
              </td>
              <td colspan="4">
                <span v-if="formtable.waterMeasure">{{
                  formtable.waterMeasure
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_waterMeasure">{{
                  formtable.evaInstitu_waterMeasure
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">照明系统</td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                照度和照明功率密度
              </td>
              <td colspan="4">
                <span v-if="formtable.lightDensity">
                  {{ formtable.lightDensity }}</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_lightDensity">{{
                  formtable.evaInstitu_lightDensity
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">
                生活热水系统
              </td>
              <td colspan="2" style="background-color: #a3c1dd2d">
                太阳能热水系统
              </td>
              <td colspan="4">
                <span v-if="formtable.gatherHot">{{
                  formtable.gatherHot
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_gatherHot">{{
                  formtable.evaInstitu_gatherHot
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <th scope="row" rowspan="14">
                选择项&nbsp;
              </th>
              <td rowspan="4" style="background-color: #a3c1dd2d">
                &nbsp;可再生能源
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;太阳能热水系统</td>
              <td style="background-color: #a3c1dd2d">集热效率&nbsp;</td>
              <td colspan="4">
                <span v-if="formtable.heatAuito">{{
                  formtable.heatAuito
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_heatAuito">{{
                  formtable.evaInstitu_heatAuito
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">
                可再生能源发电系统&nbsp;
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <span v-if="formtable.renewRatio">{{
                  formtable.renewRatio
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_renewRatio">{{
                  formtable.evaInstitu_renewRatio
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">
                &nbsp;地源热泵系统
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <span v-if="formtable.earthRatio">{{
                  formtable.earthRatio
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_earthRatio">{{
                  formtable.evaInstitu_earthRatio
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" style="background-color: #a3c1dd2d">
                风冷热泵系统
              </td>
              <td style="background-color: #a3c1dd2d">&nbsp;比例</td>
              <td colspan="4">
                <span v-if="formtable.windSystem">{{
                  formtable.windSystem
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_windSystem">{{
                  formtable.evaInstitu_windSystem
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color: #a3c1dd2d">
                &nbsp;自然通风
              </td>
              <td colspan="4">
                <span v-if="formtable.natureWind">{{
                  formtable.natureWind
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_natureWind">{{
                  formtable.evaInstitu_natureWind
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color: #a3c1dd2d">
                &nbsp;自然采光
              </td>
              <td colspan="4">
                <span v-if="formtable.natureLight">{{
                  formtable.natureLight
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_natureLight">{{
                  formtable.evaInstitu_natureLight
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color: #a3c1dd2d">
                &nbsp;有效遮阳措施
              </td>
              <td colspan="4">
                <span v-if="formtable.lightMeasure">{{
                  formtable.lightMeasure
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_lightMeasure">{{
                  formtable.evaInstitu_lightMeasure
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color: #a3c1dd2d">
                &nbsp;余热作为太阳能热水辅助热源
              </td>
              <td colspan="4">
                <span v-if="formtable.heatAid">{{ formtable.heatAid }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_heatAid">{{
                  formtable.evaInstitu_heatAid
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color: #a3c1dd2d">
                &nbsp;余热作为冬季供暖热源
              </td>
              <td colspan="4">
                <span v-if="formtable.winterHeat">{{
                  formtable.winterHeat
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_winterHeat">{{
                  formtable.evaInstitu_winterHeat
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color: #a3c1dd2d">
                &nbsp;能量回收系统
              </td>
              <td colspan="4">
                <span v-if="formtable.powerBack">{{
                  formtable.powerBack
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_powerBack">{{
                  formtable.evaInstitu_powerBack
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color: #a3c1dd2d">
                冷热源设备能效等级&nbsp;
              </td>
              <td colspan="4">
                <span v-if="formtable.energyGrade">{{
                  formtable.energyGrade
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_energyGrade">{{
                  formtable.evaInstitu_energyGrade
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color: #a3c1dd2d">
                &nbsp;供暖空调负荷预测功能
              </td>
              <td colspan="4">
                <span v-if="formtable.heatFore">{{ formtable.heatFore }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_heatFore">{{
                  formtable.evaInstitu_heatFore
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color: #a3c1dd2d">
                &nbsp;冷冻水出水温度阶段性调整
              </td>
              <td colspan="4">
                <span v-if="formtable.coldTemAdjust">{{
                  formtable.coldTemAdjust
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>

              <td>
                <span v-if="formtable.evaInstitu_coldTemAdjust">{{
                  formtable.evaInstitu_coldTemAdjust
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="3" style="background-color: #a3c1dd2d">
                &nbsp;其他新型节能措施
              </td>
              <td colspan="4">
                <span v-if="formtable.otherMeasure">{{
                  formtable.otherMeasure
                }}</span>
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaluationWay_hotPart == 1"
                  >软件评估</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 2"
                  >文件审查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 3"
                  >现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 4"
                  >性能检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 5"
                  >文件审查；现场检查</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 6"
                  >文件审查；性能检测</span
                >
                <span v-else-if="formtable.evaluationWay_hotPart == 7"
                  >文件审查；现场检查；性能检测</span
                >
                <span v-else>——</span>
              </td>
              <td>
                <span v-if="formtable.evaInstitu_otherMeasure">{{
                  formtable.evaInstitu_otherMeasure
                }}</span>
                <span v-else>——</span>
              </td>
            </tr>
            <tr>
              <td scope="row" colspan="10">
                <div class="minyong">
                  <p>1、民用建筑能效测评结论：</p>
                  <p>
                    （1）经软件模拟该项目基础相对节能率为<input
                      type="text"
                      v-model="formtable.relateEnergy"
                    />%，(设计标准为
                    <span class="minyong_select">
                      <span v-if="formtable.designStand == 1"
                        >《江苏省居住建筑热环境与节能设计标准》DGJ32/J71-2014</span
                      >
                      <span v-if="formtable.designStand == 2"
                        >《江苏省居住建筑热环境与节能设计标准》DB32/
                        4066-2021）</span
                      >
                    </span>
                    <span> ); </span>
                  </p>
                  <p>
                    （2）经测评，该项目规定项<input
                      type="text"
                      v-model="formtable.strip"
                    />条参评，均满足《民用建筑能效测评标识标准》DB32/T
                    3964-2020规定要求；
                  </p>
                  <p>
                    （3）经测评，该项目选择项<input
                      v-model="formtable.selectStrip"
                      type="text"
                    />条参评，加分<input
                      v-model="formtable.grades"
                      type="text"
                    />分；
                  </p>
                  <p>
                    （4）经测评，本项目基础项、规定项均满足《民用建筑能效测评标识标准》DB32/T
                    3964-2020标准要求，建筑节能率为<input
                      type="text"
                      v-model="formtable.buildEnergyRatio"
                    />%，测评合格。
                  </p>
                  <p>2、民用建筑能效标识建议：</p>
                  <p>
                    依据民用建筑能效测评结论，建议该建筑能效标识为
                    {{ project.stars }}星。
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row" colspan="4">测评人员&nbsp;</th>
              <th colspan="4">&nbsp;审核人员</th>
              <th colspan="4">&nbsp;批准人员</th>
            </tr>
            <tr class="table_select">
              <th colspan="4" scope="row">
                <el-select
                  v-model="formtable.evaluatUser"
                  v-loadmore="getStaffList"
                  :filter-method="fliterStaff"
                  filterable
                  placeholder="盖章/签字"
                  @focus="selectFocus(1)"
                >
                  <el-option
                    v-for="item in staffList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <!--              <input v-model="formtable.evaluatUser" placeholder="盖章/签字" type="text">-->
              </th>
              <td colspan="4">
                <el-select
                  v-model="formtable.auditUser"
                  v-loadmore="getStaffList"
                  :filter-method="fliterStaff"
                  filterable
                  placeholder="盖章/签字"
                  @focus="selectFocus(2)"
                >
                  <el-option
                    v-for="item in staffList2"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <!--              <input v-model="formtable.auditUser" placeholder="盖章/签字" type="text">-->
              </td>
              <td colspan="4">
                <el-select
                  v-model="formtable.approveUser"
                  v-loadmore="getStaffList"
                  :filter-method="fliterStaff"
                  filterable
                  placeholder="盖章/签字"
                  @focus="selectFocus(3)"
                >
                  <el-option
                    v-for="item in staffList3"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <!--              <input v-model="formtable.approveUser" placeholder="盖章/签字" type="text">-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-form>
  </div>
</template>
<script>
import { getStaff } from "../../../../../api/user";
import { checkSave, projectTableInfo } from "../../../../../api/project";

export default {
  data() {
    return {
      formtable: {},
      project: {},
      //冷水热泵机组
      jizu: [],
      //单元式空气调节机、风管送风式和屋顶调节机组
      jizu2: [],
      jizu3: [],
      jizu4: [],
      jizu5: [],
      jizu6: [],
      staffList: [],
      staffList2: [],
      staffList3: [],
      staffPage: 2,
      staffPage2: 2,
      staffPage3: 2,
      activeStaff: 1,
      edit: true,
      //1是从形式审查调过来的，2是从技术审查跳过来的
      from: 1,
      shencha1: [],
    };
  },

  mounted() {
    this.project_id = this.$route.query.project_id;
    this.getAllStaffList();
    if (this.$route.query.from) {
      this.from = this.$route.query.from;
    }
    this.getInfo();
  },

  methods: {
    //获得员工信息
    getStaffList(val = "") {
      console.log(this.activeStaff);
      let that = this;
      let params = {
        token: this.$store.state.token,
        limit: 1000,
      };
      if (this.activeStaff == 1) {
        params.page = this.staffPage;
      } else if (this.activeStaff == 2) {
        params.page = this.staffPage2;
      } else {
        params.page = this.staffPage3;
      }
      if (val != "") {
        params.name = val;
      }
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          if (this.activeStaff == 1) {
            that.staffList = that.staffList.concat(res.data);
            that.staffPage += 1;
          } else if (this.activeStaff == 2) {
            that.staffList2 = that.staffList2.concat(res.data);
            that.staffPage2 += 1;
          } else {
            that.staffList3 = that.staffList3.concat(res.data);
            that.staffPage3 += 1;
          }
        }
      });
    },
    check() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$message.success("保存成功");
        // that.goBack();
      });
    },
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.project = res.data;
        if (that.project.status > 8) {
          that.edit = false;
        }
        let checks = res.data.checks;
        that.shencha1 = checks
          .map((item) => {
            if (item.type == 2) {
              return item;
            }
          })
          .filter((d) => d);
        for (let i = 0; i < checks.length; i++) {
          if (checks[i].type == 2) {
            that.idea = checks[i].idea;
            that.pass = checks[i].pass;
          }
        }
        that.formtable = that.project.detail;
        if (!that.formtable) {
          that.formtable = {};
        }
        let jizu_total = that.project.jizhu;
        let jizu = [];
        let jizu2 = [];
        let jizu3 = [];
        let jizu4 = [];
        let jizu5 = [];
        let jizu6 = [];

        for (let i = 0; i < jizu_total.length; i++) {
          if (jizu_total[i].type == 1) {
            jizu.push(jizu_total[i]);
          } else if (jizu_total[i].type == 2) {
            jizu2.push(jizu_total[i]);
          } else if (jizu_total[i].type == 3) {
            jizu3.push(jizu_total[i]);
          } else if (jizu_total[i].type == 4) {
            jizu4.push(jizu_total[i]);
          } else if (jizu_total[i].type == 5) {
            jizu5.push(jizu_total[i]);
          } else if (jizu_total[i].type == 6) {
            jizu6.push(jizu_total[i]);
          }
        }
        console.log(jizu, jizu2);
        that.jizu = jizu;
        that.jizu2 = jizu2;
        that.jizu3 = jizu3;
        that.jizu4 = jizu4;
        that.jizu5 = jizu5;
        that.jizu6 = jizu6;
        that.$nextTick(function () {
          let table = document.getElementById("engerytable");
          let inputs = table.getElementsByTagName("input");
          for (let i = 0; i < inputs.length; i++) {
            inputs[i].disabled = true;
          }
        });
      });
    },

    pre_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$router.push({
          path: "/formal/formDetail?project_id=" + this.project_id,
        });
      });
    },
    next_page() {
      let params = {
        idea: this.idea,
        pass: this.pass,
        project_id: this.project_id,
        type: 2,
        token: this.$store.state.token,
      };
      let that = this;
      checkSave(params).then((res) => {
        console.log(res);
        that.$router.push({
          path: "/formal/reportTable?project_id=" + this.project_id,
        });
      });
    },
    getAllStaffList() {
      let params = {
        token: this.$store.state.token,
        limit: 1000,
        page: 1,
      };
      getStaff(params).then((res) => {
        if (res.data && res.data.length > 0) {
          this.staffList = res.data;
          this.staffList2 = res.data;
          this.staffList3 = res.data;
        }
      });
    },
    /**
     * 筛选企业
     */
    fliterStaff(searchVlaue) {
      this.staffList = [];
      this.staffPage = 1;
      this.getStaffList(searchVlaue);
    },
    //select聚焦
    selectFocus(active) {
      this.activeStaff = active;
      this.getStaffList();
    },
  },
};
</script>
<style scoped>
.minyong p {
  text-align: left !important;
}
.engerytable td {
  height: 40px;
  text-align: center;
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-all;
}
/deep/ .el-textarea > textarea {
  height: 513px;
}

/deep/ .table_select .el-select {
  width: 100%;
}

/deep/ .table_select .el-input {
  width: 100%;
}

/deep/ .fdrtxt .el-textarea__inner {
  border: 0;
}

/deep/ .minyong input {
  width: 100px;
  border-bottom: 1px solid #333;
}

/deep/ .minyong .minyong_select .el-input {
  width: 335px;
}

/deep/ .minyong .minyong_select input {
  width: 335px;
}

/deep/ .minyong .el-input__suffix {
  right: -75px;
}
</style>
<style scoped>
.engerytable > table > tbody > tr > td:nth-child(3) {
  text-align: center;
}

.engerytable > table > tbody > tr > td:nth-child(4) {
  text-align: center;
}

.engerytable > table > tbody > tr > td:nth-child(6) {
  text-align: center;
}

.uploadtable {
  margin: 20px 0;
}

input {
  height: 40px;
  width: 98%;
  border: 0;
  text-align: center;
}
th{
  background-color: #c3d8eebd
}
</style>
